<template>
  <div id="sidebar-demo">
    <div class="flex w-full">
      <div class="mx-auto my-auto w-full sm:w-full xl:w-10/12">
        <div class="vx-row gap-y-5 mb-base">
          <div class="vx-col w-full">
            <router-link to="/MyProfile">
              <Profile />
            </router-link>
          </div>
          <div class="vx-col w-full">
            <CurrentBallot />
          </div>
          <div class="vx-col w-full lg:w-1/3">
            <SeasonPass />
          </div>
          <div class="vx-col w-full lg:w-1/3">
            <VisitHistory />
          </div>
          <div class="vx-col w-full lg:w-1/3">
            <OpenBallot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeasonPass from './SeasonPass.vue';
import VisitHistory from './VisitHistory.vue';
import OpenBallot from './OpenBallot.vue';
import Profile from './Profile.vue';
import CurrentBallot from './CurrentBallot.vue';

export default {
  components: {
    SeasonPass,
    VisitHistory,
    OpenBallot,
    Profile,
    CurrentBallot
  }
}
</script>

<style lang="scss">
.dash_info_message_wrapper {
  .vx-card__collapsible-content {
    width: 100%;

    .vx-card__body {
      display: flex;
      height: 100%;
    }
  }
}

.dash_profile_wrapper {
  width: 100%;

  .vx-card__collapsible-content {
    width: 100%;

    .profile_avatar {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    .dash_profile_grid {
      width: calc(100% - 75px);
      margin-left: 20px;
    }
  }

  @media (max-width: 575px) {
    .vx-card__collapsible-content {
      .profile_avatar {
        width: 45px;
        height: 45px;
      }

      .dash_profile_grid {
        width: calc(100% - 60px);
        margin-left: 12px;
      }
    }
  }
}

.dash_icon_bg {
  background-color: #f2f2f2;
}

.dash_grid_bg {
  background-color: #f0f0f0;
}
</style>
