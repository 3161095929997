<template>
<vx-card class="dash_profile_wrapper h-full w-full flex justify-start" v-if="employee">
    <div slot="no-body" class="w-full flex items-center h-full sm:px-5 px-4 sm:py-4 py-3">
        <div class="flex flex-row justify-start items-center w-full">
            <vs-avatar class="m-0 profile_avatar shadow-none" avatarSize="50px"></vs-avatar>
            <div class="dash_profile_grid">
                <div class="w-full text-left mt-2 empdata">
                    <h2 class="md:text-2xl text-xl font-semibold md:mb-2 mb-1">{{ employee.displayName }}</h2>
                    <p class="sm:text-base text-sm mb-1">
                        <feather-icon icon="PhoneIcon" svgClasses="h-5 w-5 mr-1" class="align-middle" />{{ employee.mobileNumber }}
                    </p>
                    <p class="sm:text-md text-sm flex items-center">
                        <feather-icon icon="MailIcon" svgClasses="h-5 w-5 mr-1" class="align-middle" />{{ employee.emailAddress }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</vx-card>
</template>

<script>
import {
    profileData
} from '../../store/api/profile';

export default {
    data() {
        return {
            employee: undefined,
        }
    },
    async created() {
        await this.loadEmployee();
    },
    methods: {
        async loadEmployee() {
            this.employee = await profileData.SearchMyProfile()

        },
    }
}
</script>
