<template>
<vx-card card-background="#fff">
    <div class="flex w-full mb-3 md:mb-5 items-start items-center">
        <div class="dash_icon_bg flex items-center p-3 sm:p-4 rounded-lg">
            <vs-icon icon-pack="feather" icon="icon-user-plus" size="small" color="#1571b7"></vs-icon>
        </div>
        <div class="ml-2 md:ml-4">
            <h4 class="text-base md:text-lg font-semibold">Visit History</h4>
            <!-- <p class="text-xs md:text-sm">Lorem Ipsum</p> -->
        </div>
        <div slot="actions" class="flex justify-center ml-auto">
            <router-link to="/ParkingHistory">
                <vs-button class="sm:p-2 p-1 sm:w-10 w-8 sm:h-10 h-8" type="border" icon-pack="feather" icon="icon-arrow-right" size="medium"></vs-button>
            </router-link>
        </div>
    </div>
    <vs-divider />
    <div class="flex flex-col gap-y-2">
        <div v-if="parkingData.length > 0">
            <div class="dash_grid_bg p-3 rounded-lg" v-for="item in parkingData" :key="item.id">
                <h3 class="font-bold sm:text-base text-sm mb-1">{{ item.carModel }}</h3>
                <p class="text-xs">{{ item.registrationPlate }}</p>
                <p class="text-xs">{{item.entryDateTime | formatLongDateTime}}</p>
            </div>
        </div>
        <div class="dash_grid_bg p-3 rounded-lg text-center" v-else>
            <h3 class="font-bold sm:text-base text-sm mb-1">{{ noData.title }}</h3>
        </div>
    </div>
</vx-card>
</template>

<script>
import {
    visitHistoryData
} from "../../store/api/visitHistory";

export default {
    data() {
        return {
            parkingData: [],
            noData: {
                title: "No Records Found",
            }
        }
    },
    async created() {
        await this.loadVisitHistory();
    },
    methods: {
        async loadVisitHistory() {
            this.parkingData = await visitHistoryData.SearchUserHistory();
        },
    }
}
</script>
