<template>
<vx-card card-background="#fff">
    <div class="flex w-full mb-3 md:mb-5 items-start items-center">
        <div class="dash_icon_bg flex items-center p-3 sm:p-4 rounded-lg">
            <vs-icon icon-pack="feather" icon="icon-file" size="small" color="#1571b7"></vs-icon>
        </div>
        <div class="ml-2 md:ml-4">
            <h4 class="text-base md:text-lg font-semibold">Season Pass</h4>
            <!-- <p class="text-xs md:text-sm">Lorem Ipsum</p> -->
        </div>
        <div slot="actions" class="flex justify-center ml-auto">
            <router-link to="/Vehicles">
                <vs-button class="sm:p-2 p-1 sm:w-10 w-8 sm:h-10 h-8" type="border" icon-pack="feather" icon="icon-arrow-right" size="medium"></vs-button>
            </router-link>
        </div>
    </div>
    <vs-divider />
    <div class="flex flex-col gap-y-2">
        <div v-if="vehicles.length > 0">
            <div class="dash_grid_bg p-3 rounded-lg" v-for="vehicle in vehicles" :key="vehicle.id">
                <div class="flex justify-between">
                    <div>
                        <h3 class="font-bold sm:text-base text-sm mb-1">{{vehicle.registrationPlate}} ({{vehicle.registrationState}})</h3>
                        <p class="text-xs">{{ vehicle.model }}</p>
                    </div>
                    <div>
                        <vs-tooltip :text="vehicle.status == 'Pending' ? 'Pending Approval' : vehicle.status" position="right">
                            <h3 class="font-bold sm:text-base text-sm mb-1 text-right">{{ vehicle.productName }} ({{ vehicle.passNumber }})</h3>
                            <p class="text-xs text-success text-right" v-if="vehicle.status == 'Active' || vehicle.status == 'Presale'">{{vehicle.passExpiryDate | formatLongDate}}</p>
                            <p class="text-xs text-warning text-right" v-else-if="vehicle.status == 'Processing'">{{vehicle.passExpiryDate | formatLongDate}}</p>
                            <p class="text-xs text-danger text-right" v-else>{{vehicle.passExpiryDate | formatLongDate}}</p>
                        </vs-tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div class="dash_grid_bg p-3 rounded-lg text-center" v-else>
            <h3 class="font-bold sm:text-base text-sm mb-1">{{ noData.title }}</h3>
        </div>
    </div>
</vx-card>
</template>

<script>
import {
    carData
} from '../../store/api/car';
import moment from "moment";

export default {
    data() {
        return {
            vehicles: [],
            noData: {
                title: "No Records Found",
            }
        };
    },
    async created() {
        await this.loadVehicles();
    },
    methods: {
        async loadVehicles() {
            this.vehicles = await carData.GetCarByUser();
            
            if (this.vehicles.length > 0) {
                var i;
                var j = this.vehicles.length;
                for (i = 0; i < j; i++) {
                    const expDate = this.vehicles[i].passExpiryDate;
                    const today = moment().add(30, "days");
                    let compare = moment(expDate).isSameOrAfter(today);
                    if (!compare) {
                        this.vehicles[i] = {
                            ...this.vehicles[i],
                            expInMonth: true
                        };
                    }
                }
            }
        },
    }
}
</script>
