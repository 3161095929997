<template>
<vx-card card-background="#fff" v-if="ballot" class="bg-border-success">
    <div class="flex w-full mb-3 md:mb-5 items-start items-center">
        <div class="dash_icon_bg flex items-center p-3 sm:p-4 rounded-lg">
            <vs-icon icon-pack="feather" icon="icon-sunrise" size="small" color="#1571b7"></vs-icon>
        </div>
        <div class="ml-2 md:ml-4">
            <h4 class="text-base md:text-lg font-semibold">Today is a ballot event day</h4>
            <p class="text-xs md:text-sm">{{ballot.ballotDate | formatLongDate}}</p>
        </div>
    </div>
    <vs-divider />
    <div class="flex flex-col gap-y-2">
        <div v-if="ballotRequests.length > 0">
            <div class="dash_grid_bg p-3 rounded-lg" v-for="request in ballotRequests" :key="request.id">
                <div class="flex justify-between">
                    <div>
                        <h3 class="font-bold sm:text-base text-sm mb-1">{{request.registrationPlate}}</h3>
                        <p class="text-xs">{{ request.model }}</p>
                        <p class="text-xs">{{ request.passNumber }}</p>
                    </div>
                    <div>
                        <vs-chip color="success" v-if="request.ballotRequestStatus == 'Success'">Successful</vs-chip>
                        <vs-chip color="danger" v-else>Unsuccessful</vs-chip>
                    </div>
                </div>
            </div>
        </div>
        <div class="dash_grid_bg p-3 rounded-lg text-center" v-else>
            <h3 class="font-bold sm:text-base text-sm mb-1">{{ noData.title }}</h3>
        </div>
    </div>
</vx-card>
</template>

<script>
import moment from "moment";
import {
    ballotData
} from '../../store/api/ballot';

export default {
    data() {
        return {
            ballot: undefined,
            noData: {
                title: "No Records Found",
            },
            ballotRequests: [],
        };
    },
    async created() {
        await this.loadBallot();
        await this.loadUserCar();
    },
    methods: {
        async loadBallot() {
            this.ballot = await ballotData.searchTodaysBallot();
        },
        async loadUserCar() {
            if (this.ballot) {
                this.ballotRequests = await ballotData.searchRequestedBallot(this.ballot.id);
                console.log('this.ballotRequests', this.ballotRequests);
            }

        }
    }
}
</script>

<style>
.bg-border-success {
    border-color: rgba(var(--vs-success), 1) !important;
    border: solid;
}
</style>
