<template>
<vx-card card-background="#fff">
    <div class="flex w-full mb-3 md:mb-5 items-start items-center">
        <div class="dash_icon_bg flex items-center p-3 sm:p-4 rounded-lg">
            <vs-icon icon-pack="feather" icon="icon-user-check" size="small" color="#1571b7"></vs-icon>
        </div>
        <div class="ml-2 md:ml-4">
            <h4 class="text-base md:text-lg font-semibold">Open Ballot</h4>
            <!-- <p class="text-xs md:text-sm">Lorem Ipsum</p> -->
        </div>
        <div slot="actions" class="flex justify-center ml-auto">
            <router-link to="/OpenBallot">
                <vs-button class="sm:p-2 p-1 sm:w-10 w-8 sm:h-10 h-8" type="border" icon-pack="feather" icon="icon-arrow-right" size="medium"></vs-button>
            </router-link>
        </div>
    </div>
    <vs-divider />
    <div class="flex flex-col gap-y-2">
        <div v-if="ballotList.length > 0">
            <div class="dash_grid_bg p-3 rounded-lg" v-for="ballot in ballotList" :key="ballot.id">
                <div class="flex justify-between">
                    <div>
                        <h3 class="font-bold sm:text-base text-sm mb-1">Event Date : {{ ballot.ballotDate | formatLongDate }}</h3>
                        <h6 class="text-xs truncate">{{ ballot.model }} ({{ ballot.registrationPlate }})</h6>
                        <p class="text-xs truncate">{{ballot.productName}} ({{ballot.passExpiryDate | formatLongDate}})</p>
                    </div>
                    <div>
                        <vs-chip color="dark" v-if="ballot.ballotRequestStatus == 'OptedIn'">Opted In</vs-chip>
                        <vs-chip color="dark" v-else-if="ballot.ballotRequestStatus == 'OptOut'">Opted Out</vs-chip>
                        <vs-chip color="dark" v-else-if="ballot.ballotRequestStatus == 'Selected'">Pending Outcome</vs-chip>
                        <vs-chip color="dark" v-else-if="ballot.ballotRequestStatus == 'Success'">Successful</vs-chip>
                        <vs-chip color="dark" v-else-if="ballot.ballotRequestStatus == 'Failed'">Unsuccessful</vs-chip>
                    </div>
                </div>
            </div>
        </div>
        <div class="dash_grid_bg p-3 rounded-lg text-center" v-else>
            <h3 class="font-bold sm:text-base text-sm mb-1">{{ noData.title }}</h3>
        </div>

    </div>
</vx-card>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot";

import moment from 'moment'

export default {
    data() {
        return {
            ballotList: [],
            noData: {
                title: "No Records Found",
            }
        }
    },
    components: {},
    async created() {
        await this.loadBallot()
    },
    methods: {
        async loadBallot() {
            this.ballotList = await ballotData.searchCurrentBallot();
        }
    }
}
</script>
